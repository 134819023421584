import {defineStore} from 'pinia';
import API from "@/shared/helpers/API";
import {adjustColorForContrast, isContrastEnoughForText} from "@/shared/helpers/ui";
import {formatDateOnly, formatDateTime, localToServerTime} from "@/shared/helpers/dates";
import {useCartStore} from "@/new-menu/stores/cart.store";
import {useLanguageStore} from "@/shared/stores";

let cartAllergens = typeof allergens !== 'undefined' && allergens ? allergens : [];
let timeChecks = [];

export const useMenusStore = defineStore('menus', {
	state: () => ({
		business: normalizeBusiness(),
		menus: normalizeMenus(menus),
		languages: normalizeLanguages(),
		countries: normalizeCountries(),
		socials: normalizeSocials(),
		weconstudioInfo: normalizeWeconstudioInfo(),
		savedData: normalizeSavedData(),
		skusQuantities: normalizeSkusQuantities(),
		room: normalizeRoom(),
		roomTableId: roomTableId,
		roomId: roomId,
		theme: theme,
		imageZoomItem: null,
		selectedMenuId: null,
		selectedItemId: null,
		allergensOpen: false,
		otherCategoryColor: '#444444',
		fontFamily: fontFamily,
		allergens: cartAllergens,
		search: null,
		searchResults: [],
		searchResultIndex: 0,
		imageSize: getImageSize(),
		isDeselectingMenu: false,
		currentLanguageId: null,
		currentCountryId: null,
		filteredProductToAdd: null,
		filteredCategoryToShow: null,
		searchedCategoryToShow: null,
		readMode: false,
		acceptPrivacy: null,
		acceptCookies: null,
		qrType: qrType,
		qrVersion: qrVersion,
		isDeliveryNowAvailable: isDeliveryNowAvailable(),
		isDeliveryFutureAvailable: isDeliveryFutureAvailable(),
		isTakeawayAvailable: isTakeawayAvailable(),
		forceTakeaway: forceTakeaway,
		deliveryTimes: deliveryTimes,
		takeawayTimes: takeawayTimes,
		forceMenuId: forceMenuId ? Number(forceMenuId) : null,
		hideOrderHere: true,
		heatMap: heatMap,
		heatMapData: null,
		menusItemsSold: menusItemsSold,
		menusExclude: menusExclude,
		fetchedRoomTableCallId: null
	}),
	getters: {
		paymentMethodsAvailable: (state) => {
			let paymentMethods = [];
			if((state.business.plan_plugin_order_pay || state.business.plan_plugin_pay_bill) && state.business.stripe_account) {

				if(state.business.options.hide_prices) {
					if(state.business.options.accept_cash) {
						return ['cash'];
					} else {
						return [];
					}
				}

				if(state.business.options.accept_cards) {
					paymentMethods.push('stripe');
				}
				if(state.qrType) {
					if(state.business.options.accept_cash) {
						paymentMethods.push('cash');
					}
				} else {
					let mode = null;
					if(state.business.plan_plugin_delivery && !state.forceTakeaway) {
						if(state.isDeliveryNowAvailable || state.isDeliveryFutureAvailable) {
							mode = "delivery";
						}
					}
					if(mode === null && state.business.plan_plugin_takeaway) {
						if(state.isTakeawayAvailable) {
							mode = "takeaway";
						}
					}
					if(mode === "delivery" && state.business.options.delivery_cash) {
						paymentMethods.push('cash');
					}
					if(mode === "takeaway" && state.business.options.takeaway_cash) {
						paymentMethods.push('cash');
					}
				}
				if(state.business.options.accept_satispay && state.business.options_integrations.satispay_status) {
					paymentMethods.push('satispay');
				}
				if(state.business.options.accept_voucherly) {
					paymentMethods.push('voucherly');
				}
				if(state.business.options.accept_klarna) {
					paymentMethods.push('klarna');
				}
				if(state.business.options.accept_bitcoin && state.business.options_integrations.btcpay_store_id && state.business.options_integrations.btcpay_active) {
					paymentMethods.push('btc');
				}
			}
			return paymentMethods;
		},
		orderMode: (state) => {
			if(state.business.plan_plugin_order_pay && state.business.stripe_account && state.paymentMethodsAvailable.length > 0 && state.forceMenuId === null && !state.business.block_orders) {
				if(state.qrType) {
					if(state.room) {
						if(state.room.accept_orders) {
							return "qr";
						}
					} else {
						return "qr";
					}
				} else {
					if(state.business.plan_plugin_delivery && !state.forceTakeaway) {
						if(state.isDeliveryNowAvailable || state.isDeliveryFutureAvailable) {
							return "delivery";
						}
					}
					if(state.business.plan_plugin_takeaway) {
						if(state.isTakeawayAvailable) {
							return "takeaway";
						}
					}
				}
			}
			return "none";
		},
		enabledRoomTableCalls: (state) => {
			return state.room?.enabled_calls && state.room?.accept_orders && state.orderMode === "qr";
		},
		shouldShowModeSwitch: (state) => {
			return (state.isDeliveryNowAvailable || state.isDeliveryFutureAvailable) && state.isTakeawayAvailable && ['delivery', 'takeaway'].includes(state.orderMode);
		},
		businessLanguage: (state) => {
			return state.languages.find(language => language.language_id === state.business.menu_language_id);
		},
		currentLanguage: (state) => {
			if(state.currentLanguageId) {
				return state.languages.find(language => language.language_id === state.currentLanguageId);
			} else {
				return state.businessLanguage;
			}
		},
		currentCountry: (state) => {
			if(state.currentCountryId) {
				return state.countries.find(country => country.country_id === state.currentCountryId);
			} else {
				return state.getDefaultCountry();
			}
		},
		shouldTranslate: (state) => {
			return state.currentLanguageId !== null && state.currentLanguageId !== state.business.menu_language_id;
		},
		allergensFilterNum: (state) => {
			return state.allergens.filter(allergen => !allergen.active).length;
		},
		selectedMenu: (state) => {
			return state.selectedMenuId ? state.menusAvailable.find(menu => menu.menu_id === state.selectedMenuId) : null;
		},
		selectedMenuItemsQuantity: (state) => {
			return state.selectedMenu ? state.selectedMenu.categories.reduce((count, category) => {
				return count + category.items.length;
			}, 0) : 0;
		},
		selectedItem: (state) => {
			return state.selectedItemId ? state.getItemById(state.selectedItemId) : null;
		},
		allCategories: (state) => {
			return state.menusAvailable.reduce((categories, menu) => {
				return categories.concat(menu.categories);
			}, []);
		},
		allProducts: (state) => {
			return state.allCategories.reduce((products, category) => {
				return products.concat(category.items);
			}, []);
		},
		searchResultsCurrent: (state) => {
			return state.searchResults.filter(result => {
				return state.selectedMenuId !== null && result.fullMatch && result.menu_id === state.selectedMenuId;
			});
		},
		menuHasWizard() {
			if (!this.selectedMenu) {
				return false;
			}

			let categoryExpandedManyItems = this.selectedMenu.categories.some(cat => {
				return !cat.dropdown && cat.items.length >= 10;
			});
			if (categoryExpandedManyItems && this.selectedMenu.categories.length >= 2) {
				return true;
			}
			let manyCategoriesExpanded = this.selectedMenu.categories.filter(cat => {
				return !cat.dropdown && cat.items.length >= 3;
			}).length >= 4;
			return manyCategoriesExpanded;
		},
		payBillAvailable: (state) => {
			if(!state.business.plan_plugin_pay_bill) {
				return false;
			}
			let isQr = !!state.qrType && state.qrVersion >= 3;
			let roomPayBill = state.room && state.room.enable_pay_bill;
			let numPaymentMethods = state.paymentMethodsAvailable.length;
			let isPaymentMethodOtherThanCashAvailable = numPaymentMethods > 1 || (numPaymentMethods === 1 && state.paymentMethodsAvailable[0] !== "cash");
			return isQr && roomPayBill && isPaymentMethodOtherThanCashAvailable;
		},
		menusAvailable: (state) => {
			return state.menus.filter(menu => state.isMenuAvailable(menu)).map(menu => {
				return {
					...menu,
					categories: menu.categories.filter(category => state.isCategoryAvailable(category)).map(category => {
						return {
							...category,
							items: category.items.filter(item => state.isItemAvailable(item))
						};
					})
				};
			});
		},
		selectedMenuMostPopularItemsIds: (state) => {
			if(!state.selectedMenu) {
				return [];
			}
			let itemsCount = state.selectedMenuItemsQuantity;
			let popularMax;
			if(itemsCount <= 10) {
				popularMax = 1;
			} else if(itemsCount <= 30) {
				popularMax = 2;
			} else if(itemsCount <= 50) {
				popularMax = 3;
			} else {
				popularMax = 4;
			}

			let popularItems = [];
			for(let i = 0; i < state.selectedMenu.categories.length; i++) {
				let category = state.selectedMenu.categories[i];
				for(let j = 0; j < category.items.length; j++) {
					let item = category.items[j];
					if(item.mostSold && item.orderability === "standard" && state.isItemAvailable(item)) {
						popularItems.push(item);
					}
				}
			}
			popularItems.sort((a, b) => {
				return b.mostSold - a.mostSold;
			});
			return popularItems.slice(0, popularMax).map(item => item.menu_item_id);
		}
	},
	actions: {
		getDefaultCountry() {
			if(typeof COUNTRY !== 'undefined') {
				let findResult = this.countries.find(country => country.code && country.code === COUNTRY);
				if(findResult) {
					return findResult;
				}
			}
			if(this.countries.length > 0) {
				return this.countries[0];
			}
			return null;
		},
		getOriginalItem(item) {
			for(let i = 0; i < this.menus.length; i++) {
				let menu = this.menus[i];
				for(let j = 0; j < menu.categories.length; j++) {
					let category = menu.categories[j];
					if(category.menu_category_id === item.category_id) {
						for(let k = 0; k < category.items.length; k++) {
							let originalItem = category.items[k];
							if(originalItem.menu_item_id === item.menu_item_id) {
								return originalItem;
							}
						}
					}
				}
			}
			return null;
		},
		getOriginalItemById(itemId) {
			for(let i = 0; i < this.menus.length; i++) {
				let menu = this.menus[i];
				for(let j = 0; j < menu.categories.length; j++) {
					let category = menu.categories[j];
					for(let k = 0; k < category.items.length; k++) {
						let originalItem = category.items[k];
						if(originalItem.menu_item_id === itemId) {
							return originalItem;
						}
					}
				}
			}
			return null;
		},
		getOriginalCategoryById(categoryId) {
			for(let i = 0; i < this.menus.length; i++) {
				let menu = this.menus[i];
				for(let j = 0; j < menu.categories.length; j++) {
					let category = menu.categories[j];
					if(category.menu_category_id === categoryId) {
						return category;
					}
				}
			}
			return null;
		},
		isMenuAvailable(menu) {
			return menu.available && menu.categories.some(category => this.isCategoryAvailable(category)) && this.menusExclude.indexOf(menu.menu_id) === -1;
		},
		isCategoryAvailable(category) {
			if(this.orderMode === "delivery" && !category.active_delivery) {
				return false;
			} else if(this.orderMode === "takeaway" && !category.active_takeaway) {
				return false;
			}
			if(!!category.variations_only) { return false }
			if(category.items.length > 0) {
				if(category.active === null ? category.activeTime : category.active) {
					return category.items.length > 0 && category.items.some(item => this.isItemAvailable(item));
				}
			}
			return false;
		},
		isItemAvailable(item) {
			return item.available === null ? item.availableTime : item.available;
		},
		toggleReadMode(force = null) {
			this.readMode = force !== null ? force : !this.readMode;
			localStorage.setItem('readMode', this.readMode ? '1' : '0');
			if(this.readMode) {
				document.documentElement.style.setProperty('--font-size-s', "18px");
				document.documentElement.style.setProperty('--font-size', "20px");
				document.documentElement.style.setProperty('--font-size-m', "21px");
				document.documentElement.style.setProperty('--font-size-l', "22px");
				document.documentElement.style.setProperty('--font-size-xl', "24px");
				document.documentElement.style.setProperty('--font-size-xxl', "27px");
				document.documentElement.style.setProperty('--font-size-xxxl', "30px");

				document.documentElement.style.setProperty('--line-height-s', "22px");
				document.documentElement.style.setProperty('--line-height', "25px");
				document.documentElement.style.setProperty('--line-height-m', "26px");
				document.documentElement.style.setProperty('--line-height-l', "28px");
				document.documentElement.style.setProperty('--line-height-xl', "32px");
				document.documentElement.style.setProperty('--line-height-xxl', "35px");
				document.documentElement.style.setProperty('--line-height-xxxl', "42px");
			} else {
				document.documentElement.style.setProperty('--font-size-s', "13px");
				document.documentElement.style.setProperty('--font-size', "15px");
				document.documentElement.style.setProperty('--font-size-m', "16px");
				document.documentElement.style.setProperty('--font-size-l', "17px");
				document.documentElement.style.setProperty('--font-size-xl', "19px");
				document.documentElement.style.setProperty('--font-size-xxl', "21px");
				document.documentElement.style.setProperty('--font-size-xxxl', "23px");

				document.documentElement.style.setProperty('--line-height-s', "17px");
				document.documentElement.style.setProperty('--line-height', "19px");
				document.documentElement.style.setProperty('--line-height-m', "20px");
				document.documentElement.style.setProperty('--line-height-l', "21px");
				document.documentElement.style.setProperty('--line-height-xl', "23px");
				document.documentElement.style.setProperty('--line-height-xxl', "26px");
				document.documentElement.style.setProperty('--line-height-xxxl', "30px");
			}
		},
		updateSearch(search) {
			this.search = search;
			this.searchResultIndex = 0;
			if(search) {
				let results = [];
				let searchLower = search.toLowerCase();

				this.allCategories.forEach(category => {
					let categorySearched = false;
					let fullMatch = false;
					if(category.nameTranslation.toLowerCase().includes(searchLower)) {
						categorySearched = true;
						fullMatch = true;
					}

					let resultsItems = [];
					category.items.forEach(item => {
						let originalItem = this.getOriginalItem(item);
						if(item.nameTranslation.toLowerCase().includes(searchLower)) {
							categorySearched = true;

							resultsItems.push({
								type: 'item',
								item: item,
								menu_id: category.menu_id,
								fullMatch: true
							});
							if(originalItem) {
								originalItem.searched = true;
							}
						} else {
							if(originalItem) {
								originalItem.searched = false;
							}
						}
					});

					let originalMenu = this.menus.find(menu => menu.menu_id === category.menu_id);
					let originalCategory = originalMenu ? originalMenu.categories.find(cat => cat.menu_category_id === category.menu_category_id) : null;
					if(categorySearched) {
						results.push({
							type: 'category',
							category: category,
							menu_id: category.menu_id,
							fullMatch: fullMatch
						});
						results = results.concat(resultsItems);
						if(originalCategory) {
							originalCategory.searched = true;
							originalCategory.searchedFullMatch = fullMatch;
						}
					} else {
						if(originalCategory) {
							originalCategory.searched = false;
							originalCategory.searchedFullMatch = false;
						}
					}
				});

				this.searchResults = results;
			} else {
				this.searchResults = [];
				this.menus.forEach(menu => {
					menu.categories.forEach(category => {
						category.searched = false;
						category.searchedFullMatch = false;
						category.items.forEach(item => {
							item.searched = false;
						});
					});
				});
			}
		},
		getItemById(productId) {
			return this.allProducts.find(product => product.menu_item_id === productId);
		},
		getCategoryById(categoryId) {
			return this.allCategories.find(category => category.menu_category_id === categoryId);
		},
		getMenuById(menuId) {
			return this.menusAvailable.find(menu => menu.menu_id === menuId);
		},
		getItemExtras(item, callback = null) {

			API.init().getNewMenuItemExtras(this.business.business_id, item.menu_item_id, (r) => {
				if(r && r.result && r.result === "OK") {
					normalizeItemExtras(item, r, this.menus);
					callback && callback(true);
				} else {
					callback && callback(false);
				}
			});

		},
		getProductCategory(product) {
			if(product.category_id) {
				let cat = this.allCategories.find(category => category.menu_category_id === product.category_id);
				return cat !== undefined ? cat : null;
			} else {
				return null;
			}
		},
		saveAllergensFilter() {
			localStorage.setItem('allergens', JSON.stringify(this.allergens.map(allergen => {
				return {
					allergen_id: allergen.allergen_id,
					active: allergen.active,
				}
			})));
		},
		loadAllergensFilter() {
			if(!this.business.options.allergens_filter) {
				return;
			}
			let allergens = localStorage.getItem('allergens');
			if(allergens) {
				allergens = JSON.parse(allergens);
				this.allergens.forEach(allergen => {
					let found = allergens.find(a => a.allergen_id === allergen.allergen_id);
					if(found) {
						allergen.active = found.active;
					}
				});
			}
		},
		goToMenu(menuId, noScroll = false) {
			this.selectedMenuId = menuId;
			if(!noScroll) {
				requestAnimationFrame(() => {
					requestAnimationFrame(() => {
						this.scrollToMenuTop();
					});
				});
			}
		},
		backToMultimenu() {
			this.isDeselectingMenu = true;
			setTimeout(() => {
				this.isDeselectingMenu = false;
				this.selectedMenuId = null;
				if(!this.forceMenuId) {
					this.scrollToMenuTop();
				}
			}, 500);
		},
		scrollToCategory(category, calcLoss = true) {

			let el = document.getElementById("cat-head-"+category.menu_category_id);
			if(!el) {
				return;
			}
			let categoryRect = el.getBoundingClientRect();

			let heightLoss = 0;
			if(calcLoss) {
				//Height loss is the height of any expanded content of categories above the one we want to scroll to
				for(let i = 0; i < this.selectedMenu.categories.length; i++) {
					let cat = this.selectedMenu.categories[i];
					if(cat.menu_category_id === category.menu_category_id) {
						break;
					}
					//Checks the class to not repeat the same logic to see if the category is expanded
					//It shall be expanded because it's toggleable to count in the height loss
					let catEl = document.getElementById("cat-head-"+cat.menu_category_id);
					if(catEl && !catEl.classList.contains("isCollapsed") && cat.dropdown) {
						let catItems = document.getElementById("cat-items-"+cat.menu_category_id);
						if(catItems) {
							heightLoss += catItems.getBoundingClientRect().height;
						}
					}
				}
			}

			let topContentHeight = this.menuHasWizard ? 96 : 56;
			let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
			window.scroll({top: categoryRect.top + currentScroll - heightLoss - topContentHeight, behavior: 'smooth'});

		},
		scrollToMenuTop() {

			let menuTop = document.getElementById("menu-body");
			if(!menuTop) {
				return;
			}

			let topContentHeight = 0;
			if(this.selectedMenuId) {
				topContentHeight = this.menuHasWizard ? 85 : 45;
			}
			let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
			window.scroll({top: menuTop.getBoundingClientRect().top + currentScroll - topContentHeight, behavior: 'smooth'});

		},
		getFullImageUrl(image) {
			if(image && (image.includes("qromo.s3") || image.includes("qromomilan.s3") || image.includes("img.qromo.io"))) {
				let fullURL = image.split(".");
				fullURL[fullURL.length-2] += "-full";
				fullURL = fullURL.join(".");
				return fullURL.replace("thumbnail", "original");
			} else {
				return null;
			}
		},
		getItemFromSlug(slug) {
			if(this.selectedMenu) {
				let foundItem = null;
				for(let i = 0; i < this.selectedMenu.categories.length; i++) {
					let category = this.selectedMenu.categories[i];
					let item = category.items.find(item => item.slug === slug);
					if(item) {
						foundItem = item;
						break;
					}
				}
				return foundItem;
			}
			return null;
		},
		performTimesCheck(callback = null) {
			if(timeChecks.length > 0) {

				API.init().checkAvailability(this.business.business_id, timeChecks, (r) => {
					if(r && r.result === "OK") {
						let itemsResponse = r.items;
						for(let i = 0; i < itemsResponse.length; i++) {
							let itemResponse = itemsResponse[i];
							let itemTime = timeChecks.find(itemTime => itemTime.type === "item" && itemTime.item_id === Number(itemResponse.item_id));
							if(itemTime && itemResponse) {
								let item = this.getOriginalItemById(itemTime.item_id);
								if(item) {
									item.availableTime = Number(itemResponse.quantity_ordered) < itemTime.num_items;
									let availableQuantity = itemTime.num_items - Number(itemResponse.quantity_ordered);
									item.availableQuantity = availableQuantity;
									if(item.availableQuantity) {
										item.availableQuantity = Math.min(item.availableQuantity, availableQuantity);
									}
									if(item.availableQuantity < 0) {
										item.availableQuantity = 0;
									}
								}
							}
						}
						let categoriesResponse = r.categories;
						for(let i = 0; i < categoriesResponse.length; i++) {
							let categoryResponse = categoriesResponse[i];
							let categoryTime = timeChecks.find(categoryTime => categoryTime.type === "category" && categoryTime.category_id === Number(categoryResponse.category_id));
							if(categoryTime && categoryResponse) {
								let originalCategory = this.getOriginalCategoryById(categoryTime.category_id);
								if(originalCategory) {
									originalCategory.activeTime = Number(categoryResponse.quantity_ordered) < categoryTime.num_items;
									originalCategory.activeQuantity = categoryTime.num_items - Number(categoryResponse.quantity_ordered);
									if(originalCategory.activeQuantity < 0) {
										originalCategory.activeQuantity = 0;
									}
								}
							}
						}
						this.isDeliveryNowAvailable = r.hasOwnProperty("delivery") ? !!r.delivery : this.isDeliveryNowAvailable;
					}
					callback && callback();
				});

			} else {
				callback && callback();
			}
		},
		checkTimesDay(mode, date, callbackTimes = null) {

			if(!date) {
				return;
			}
			let dateOnly = formatDateOnly(date);
			let serverHourZero = formatDateTime(localToServerTime(dateOnly));

			if(mode === "delivery") {

				API.init().getDeliveryTimesDay(this.business.business_id, serverHourZero, r => {
					if(r && r.result === "OK" && r.time_ranges) {
						let cartStore = useCartStore();
						let menusStore = useMenusStore();
						if(dateOnly === formatDateOnly(cartStore.notInPlaceOrderDate) && mode === menusStore.orderMode) {
							callbackTimes && callbackTimes(r.time_ranges);
						}
					}
				});

			} else {

				API.init().getTakeawayTimesDay(this.business.business_id, serverHourZero, r => {
					if(r && r.result === "OK" && r.time_ranges) {
						let cartStore = useCartStore();
						let menusStore = useMenusStore();
						if(dateOnly === formatDateOnly(cartStore.notInPlaceOrderDate) && mode === menusStore.orderMode) {
							callbackTimes && callbackTimes(r.time_ranges);
						}
					}
				});

			}

		},
		saveData() {

			let cartStore = useCartStore();
			let email = cartStore.customerEmail;
			let phone = cartStore.customerPhone;
			let name = cartStore.customerName;
			let privacy = this.acceptPrivacy;
			let cookies = this.acceptCookies;

			API.init().saveCsData(JSON.stringify({
				email: email,
				phone: phone,
				name: name,
				privacy: privacy,
				cookies: cookies,
			}), () => {

			});

		},
		loadSavedData() {

			let cartStore = useCartStore();
			cartStore.customerEmail = this.savedData.email ? this.savedData.email : null;
			cartStore.customerPhone = this.savedData.phone ? this.savedData.phone : null;
			cartStore.customerName = this.savedData.name ? this.savedData.name : null;
			this.acceptPrivacy = this.savedData && this.savedData.hasOwnProperty("privacy") && this.savedData.privacy !== null ? this.savedData.privacy : null;
			this.acceptCookies = this.savedData && this.savedData.hasOwnProperty("cookies") && this.savedData.cookies !== null ? this.savedData.cookies : null;

		},
	}
});

function normalizeSavedData() {
	if(typeof savedData !== 'undefined' && savedData) {
		savedData.privacy = savedData.privacy ? savedData.privacy === "1" : null;
		savedData.cookies = savedData.cookies ? savedData.cookies === "1" : null;
		return savedData;
	}
	return {};
}

function normalizeBusiness() {
	if(typeof business === 'undefined' || typeof businessOptions === 'undefined' || typeof businessOptionsIntegrations === 'undefined') {
		console.warn("Business not found")
		return null;
	}
	business.plan_plugin_order_pay = business.plan_plugin_order_pay === '1';
	business.plan_plugin_delivery = business.plan_plugin_delivery === '1';
	business.plan_plugin_takeaway = business.plan_plugin_takeaway === '1';
	business.plan_plugin_pay_bill = business.plan_plugin_pay_bill === '1';
	business.plan_plugin_counter = business.plan_plugin_counter === '1';
	business.block_orders = business.block_orders === '1';
	business.color = business.color ? business.color.replace('#', '') : '0752A3';
	business.menu_language_id = Number(business.menu_language_id);
	business.average_rating = Number(business.average_rating);
	business.descriptionTranslation = business.description;
	business.stripe_account = business.stripe_account ? business.stripe_account : null;

	business.options = businessOptions;
	business.options.hide_notes = business.options.hide_notes === '1';
	business.options.hide_prices = business.options.hide_prices === '1';
	business.options.enabled_search = business.options.enabled_search === '1';
	business.options.disable_feedbacks = business.options.disable_feedbacks === '1';
	business.options.private_feedbacks = business.options.private_feedbacks === '1';
	business.options.allergens_filter = business.options.allergens_filter === '1';
	business.options.read_mode = business.options.read_mode === '1';
	business.options.delivery = business.options.delivery === '1';
	business.options.delivery_cash = business.options.delivery_cash === '1';
	business.options.takeaway = business.options.takeaway === '1';
	business.options.takeaway_cash = business.options.takeaway_cash === '1';
	business.options.delivery_courier = business.options.delivery_courier === '1';
	business.options.queue_active_menu = business.options.queue_active_menu === '1';
	business.options.accept_cash = business.options.accept_cash === '1';
	business.options.accept_cards = business.options.accept_cards === '1';
	business.options.accept_klarna = business.options.accept_klarna === '1';
	business.options.accept_bitcoin = business.options.accept_bitcoin === '1';
	business.options.accept_satispay = business.options.accept_satispay === '1';
	business.options.accept_voucherly = business.options.accept_voucherly === '1';
	business.options.force_privacy = business.options.force_privacy === '1';
	business.options.enabled_box_email_cash = business.options.enabled_box_email_cash === '1';
	business.options.coupons = business.options.coupons === '1';
	business.options.cross_selling = business.options.cross_selling === '1';
	business.options.cross_selling_popup = business.options.cross_selling_popup === '1';
	business.options.min_order = business.options.min_order !== null ? Number(business.options.min_order) : null;
	business.options.delivery_costs = business.options.delivery_costs ? Number(business.options.delivery_costs) : null;
	business.options.takeaway_costs = business.options.takeaway_costs ? Number(business.options.takeaway_costs) : null;
	business.options.delivery_min_order = business.options.delivery_min_order ? Number(business.options.delivery_min_order) : null;
	business.options.takeaway_min_order = business.options.takeaway_min_order ? Number(business.options.takeaway_min_order) : null;
	business.options.delivery_future_days = business.options.delivery_future_days ? Number(business.options.delivery_future_days) : null;
	business.options.takeaway_future_days = business.options.takeaway_future_days !== null ? Number(business.options.takeaway_future_days) : 0;
	business.options.delivery_courier_free_shipping = business.options.delivery_courier_free_shipping ? Number(business.options.delivery_courier_free_shipping) : null;
	business.options.delivery_courier_pickup_delay = business.options.delivery_courier_pickup_delay ? Number(business.options.delivery_courier_pickup_delay) : 1;
	business.options.buffer_time = business.options.buffer_time ? Number(business.options.buffer_time) : null;
	business.options.buffer_customers = business.options.buffer_customers ? Number(business.options.buffer_customers) : null;

	business.options_integrations = businessOptionsIntegrations;
	business.options_integrations.satispay_status = Number(business.options_integrations.satispay_status);
	business.options_integrations.btcpay_active = business.options_integrations.btcpay_active === '1';

	return business;
}

function normalizeMenus(menus) {
	if(typeof menusCategories === 'undefined' || typeof menusItems === 'undefined' || typeof menusItemsAllergens === 'undefined' || typeof menusItemsTimes === 'undefined' || typeof menusCategoriesTimes === 'undefined' || typeof menusItemsSold === 'undefined' || typeof menusItemsAssociations === 'undefined' || typeof warehouseItems === 'undefined') {
		console.warn("Menus not found")
		return [];
	}

	menusItemsTimes.forEach(time => {
		time.item_id = Number(time.item_id);
		time.hour_start = Number(time.hour_start);
		time.hour_end = Number(time.hour_end);
		time.minute_start = Number(time.minute_start);
		time.minute_end = Number(time.minute_end);
		time.num_items = time.num_items ? Number(time.num_items) : null;
	});

	menusCategoriesTimes.forEach(time => {
		time.category_id = Number(time.category_id);
		time.hour_start = Number(time.hour_start);
		time.hour_end = Number(time.hour_end);
		time.minute_start = Number(time.minute_start);
		time.minute_end = Number(time.minute_end);
		time.num_items = time.num_items ? Number(time.num_items) : null;
	});

	let allergensDataObj = cartAllergens.reduce((obj, allergen) => {
		obj[allergen.allergen_id] = allergen;
		return obj;
	}, {});

	let mostSoldObj = {};
	for(let i = 0; i < menusItemsSold.length; i++) {
		let sold = menusItemsSold[i];
		mostSoldObj[sold.id] = Number(sold.qs);
	}

	let associationsObj = {};
	for(let i = 0; i < menusItemsAssociations.length; i++) {
		let association = menusItemsAssociations[i];
		if(!associationsObj.hasOwnProperty(association.id1)) {
			associationsObj[association.id1] = [];
		}
		associationsObj[association.id1].push({
			id: association.id2,
			qs: Number(association.qs),
		});
		if(!associationsObj.hasOwnProperty(association.id2)) {
			associationsObj[association.id2] = [];
		}
		associationsObj[association.id2].push({
			id: association.id1,
			qs: Number(association.qs),
		});
	}

	let allergensObj = {};
	let allergensReverseObj = {};
	for(let i = 0; i < menusItemsAllergens.length; i++) {
		let allergen = menusItemsAllergens[i];
		if(!allergensObj.hasOwnProperty(allergen.menu_item_id)) {
			allergensObj[allergen.menu_item_id] = [];
		}
		allergensObj[allergen.menu_item_id].push(allergensDataObj[allergen.allergen_id]);

		if(!allergensReverseObj.hasOwnProperty(allergen.allergen_id)) {
			allergensReverseObj[allergen.allergen_id] = [];
		}
		allergensReverseObj[allergen.allergen_id].push(allergen.menu_item_id);
	}
	for(let i = 0; i < cartAllergens.length; i++) {
		cartAllergens[i].active = true;
		if(allergensReverseObj.hasOwnProperty(cartAllergens[i].allergen_id)) {
			cartAllergens[i].itemsIds = allergensReverseObj[cartAllergens[i].allergen_id];
		} else {
			cartAllergens[i].itemsIds = [];
		}
	}

	let warehouseItemsObj = {};
	for(let i = 0; i < warehouseItems.length; i++) {
		let warehouseItem = warehouseItems[i];
		warehouseItemsObj[warehouseItem.sku] = Number(warehouseItem.available);
	}

	let menusResult = [];
	for(let i = 0; i < menus.length; i++) {
		menus[i].categories = menusCategories.filter(category => category.menu_id === menus[i].menu_id).sort((a, b) => a.order_number - b.order_number);
		let menu = normalizeMenu(menus[i]);
		for(let j = 0; j < menu.categories.length; j++) {
			menu.categories[j].items = menusItems.filter(item => item.category_id === menu.categories[j].menu_category_id).sort((a, b) => a.order_number - b.order_number);
			let category = normalizeCategory(menu.categories[j]);
			let noItemsPictures = category.taxonomy === null;
			for(let k = 0; k < category.items.length; k++) {
				let item = category.items[k];
				category.items[k] = normalizeItem(item, allergensObj, mostSoldObj, associationsObj, warehouseItemsObj);
				if(!!item.picture) {
					noItemsPictures = false;
				}
			}
			category.noItemsPictures = noItemsPictures;
		}
		menusResult.push(menu);
	}
	menusResult = menusResult.sort((a, b) => a.order_number - b.order_number);

	return menusResult;
}

function normalizeMenu(menu) {
	menu.menu_id = Number(menu.menu_id);
	menu.color = menu.color ? menu.color.replace('#', '') : null;
	menu.order_number = Number(menu.order_number);
	if(menu.color) {
		let themeBg2 = theme ? theme.bg_2.replace('#', '') : null;
		if(themeBg2 && !isContrastEnoughForText(menu.color, themeBg2)) {
			console.warn("Contrast between menu color ("+menu.name+") and theme is not enough for text readability, forcing menu color to darker/lighter color.")
			menu.color = adjustColorForContrast(menu.color, themeBg2);
		}
	}
	menu.nameTranslation = menu.name;
	menu.available = menu.available === '1';
	return menu;
}

function normalizeCategory(category) {
	category.menu_category_id = Number(category.menu_category_id);
	category.menu_id = Number(category.menu_id);
	category.order_number = Number(category.order_number);
	category.description = category.description ? category.description : null;
	category.dropdown = category.dropdown === '1';
	category.featured = category.featured === '1';
	category.variations_only = category.variations_only === '1';
	category.grid_mode = category.grid_mode === '1';
	category.carousel = category.carousel === '1';
	category.active = category.active !== null ? category.active === '1' : null;
	category.activeTime = category.active === null ? getCurrentTimeRange(menusCategoriesTimes.filter(time => time.category_id === category.menu_category_id), "category") : null;
	category.activeQuantity = null;
	category.active_delivery = category.active_delivery === '1';
	category.active_takeaway = category.active_takeaway === '1';
	category.color = category.color ? category.color.replace('#', '') : null;
	category.isOpen = !category.dropdown;
	category.isOpenDelayed = !category.dropdown;
	category.searched = false;
	category.searchedFullMatch = false;
	category.nameTranslation = category.name;
	category.descriptionTranslation = category.description;
	return category;
}

function normalizeItem(item, allergensObj, mostSoldObj, associationsObj, warehouseItemsObj) {
	if(allergensObj.hasOwnProperty(item.menu_item_id)) {
		item.allergens = allergensObj[item.menu_item_id];
	} else {
		item.allergens = [];
	}
	if(mostSoldObj.hasOwnProperty(item.menu_item_id)) {
		item.mostSold = mostSoldObj[item.menu_item_id];
	} else {
		item.mostSold = null;
	}
	if(associationsObj.hasOwnProperty(item.menu_item_id)) {
		item.associations = associationsObj[item.menu_item_id];
		item.associations = item.associations.sort((a, b) => b.qs - a.qs);
	} else {
		item.associations = [];
	}
	item.menu_item_id = Number(item.menu_item_id);
	item.price = Number(item.price);
	item.order_number = Number(item.order_number);
	item.category_id = item.category_id ? Number(item.category_id) : null;
	item.full_price = item.full_price ? Number(item.full_price) : null;
	item.cross_selling_priority = item.csp ? Number(item.csp) : null;
	item.composite = item.composite === '1';
	item.deepen = item.deepen === '1';
	item.warehouse = item.warehouse === '1';
	item.available = item.available !== null ? item.available === '1' : null;
	item.availableQuantity = null;
	if(item.warehouse && warehouseItemsObj.hasOwnProperty(item.sku)) {
		if(warehouseItemsObj[item.sku] <= 0) {
			item.available = false;
		} else {
			item.availableQuantity = warehouseItemsObj[item.sku];
		}
	}
	item.availableTime = item.available === null ? getCurrentTimeRange(menusItemsTimes.filter(time => time.item_id === item.menu_item_id), "item") : null;
	item.color = item.color ? item.color.replace('#', '') : null;
	if(item.color) {
		let themeBg2 = theme ? theme.bg_2.replace('#', '') : null;
		if(themeBg2 && !isContrastEnoughForText(item.color, themeBg2)) {
			console.warn("Contrast between item color ("+item.name+") and theme is not enough for text readability, not using item color.")
			item.color = null;
		}
	}
	item.ingredientsFull = item.ingredients && item.ingredients.length > 110 ? item.ingredients : null;
	item.ingredients = item.ingredients && item.ingredients.length > 110 ? item.ingredients.substring(0, 107) + "..." : item.ingredients;
	item.lists = [];
	item.images = [];
	item.loadedExtras = false;
	item.note = null;
	item.searched = false;
	item.long_description = null;
	item.video_link = null;
	item.materials = null;
	item.nameTranslation = item.name;
	item.ingredientsTranslation = item.ingredients;
	item.ingredientsFullTranslation = item.ingredientsFull;
	item.long_descriptionTranslation = item.long_description;
	item.materialsTranslation = item.materials;
	return item;
}

function normalizeSkusQuantities() {
	if(typeof warehouseItems === 'undefined') {
		console.warn("Warehouse not found")
		return [];
	}

	let skusQuantities = {};
	for(let i = 0; i < warehouseItems.length; i++) {
		let warehouseItem = warehouseItems[i];
		skusQuantities[warehouseItem.sku] = Number(warehouseItem.available);
	}
	return skusQuantities;
}

function normalizeRoom() {
	if(typeof room === 'undefined' || !room) {
		return null;
	}
	room.room_id = Number(room.room_id);
	room.accept_orders = room.accept_orders === '1';
	room.enabled_sms = room.enabled_sms === '1';
	room.enabled_calls = room.enabled_calls === '1';
	room.enable_pay_bill = room.enable_pay_bill === '1';
	room.cover_charge = room.cover_charge ? Number(room.cover_charge) : null;
	room.cover_charge_type = room.cover_charge_type === '1';
	room.cover_charge_everytime = room.cover_charge_everytime === '1';
	room.cover_charge_nameTranslation = room.cover_charge_name;
	room.force_pay_bill = room.force_pay_bill === '1';
	return room;
}

function isDeliveryNowAvailable() {
	if(qrType === null && Number(business.plan_plugin_delivery) === 1 && Number(business.options.delivery) === 1 && deliveryTimes) {
		return getCurrentTimeRange(deliveryTimes, "delivery");
	}
	return false;
}

function isDeliveryFutureAvailable() {
	if(qrType === null && Number(business.plan_plugin_delivery) === 1 && Number(business.options.delivery) === 1 && deliveryTimes && business.options.delivery_future_days !== null && !business.options.delivery_courier) {
		return futureTimesAvailable(deliveryTimes, Number(business.options.delivery_future_days));
	}
	return false;
}

function isTakeawayAvailable() {
	if(qrType === null && Number(business.plan_plugin_takeaway) === 1 && Number(business.options.takeaway) === 1 && takeawayTimes) {
		return futureTimesAvailable(takeawayTimes, Number(business.options.takeaway_future_days));
	}
	return false;
}

function getCurrentTimeRange(times, type) {
	let now = new Date();
	let nowHours = now.getHours();
	let nowMinutes = now.getMinutes();
	let nowTime = nowHours + (nowMinutes / 60);
	let weekday = now.getDay();
	for(let i = 0; i < times.length; i++) {
		let time = times[i];
		let timeWeekday = time.hasOwnProperty("date_week") ? Number(time.date_week) : null;
		if(timeWeekday !== null && (timeWeekday+1)%7 !== weekday) {
			continue;
		}
		let start = Number(time.hour_start) + (Number(time.minute_start) / 60);
		let end = Number(time.hour_end) + (Number(time.minute_end) / 60);
		if(start <= nowTime && end >= nowTime) {
			if(time.num_items) {
				let checkObj = {
					num_items: time.num_items,
					start_hour: time.hour_start,
					start_minute: time.minute_start,
					type: type
				}
				if(type === "item") {
					checkObj.item_id = time.item_id;
				}
				if(type === "category") {
					checkObj.category_id = time.category_id;
				}
				timeChecks.push(checkObj);
			} else if(time.num_orders) {
				let checkObj = {
					num_orders: time.num_orders,
					start_hour: time.hour_start,
					start_minute: time.minute_start,
					type: type
				}
				timeChecks.push(checkObj);
			}
			return true;
		}
	}
	return false;
}

function futureTimesAvailable(times, days) {
	let now = new Date();
	let weekday = now.getDay();
	let allowedDays = [weekday];
	for(let i = 1; i <= days; i++) {
		let day = (weekday + i) % 7;
		allowedDays.push(day);
	}
	for(let i = 0; i < times.length; i++) {
		let time = times[i];
		let timeWeekday = time.hasOwnProperty("date_week") ? Number(time.date_week) : null;
		if (timeWeekday !== null && allowedDays.includes((timeWeekday+1)%7)) {
			return true;
		}
	}
	return false;
}

function normalizeItemExtras(item, data, menus) {

	item.loadedExtras = true;

	if(data.hasOwnProperty("deepen")) {
		item.images = data.deepen.images ? data.deepen.images : [];
		item.images = item.images.sort((a, b) => {
			return Number(a.order_number) - Number(b.order_number);
		});
		item.note = data.deepen.data.note ? data.deepen.data.note === '1' : null;
		item.long_description = data.deepen.data.long_description ? data.deepen.data.long_description : null;
		item.video_link = data.deepen.data.video_link ? data.deepen.data.video_link : null;
		item.materials = data.deepen.data.materials ? data.deepen.data.materials : null;
		item.long_descriptionTranslation = item.long_description;
		item.materialsTranslation = item.materials;
	}

	if(data.hasOwnProperty("composite")) {

		let allItemsObj = menus.reduce((obj, menu) => {
			menu.categories.forEach(category => {
				category.items.forEach(item => {
					obj[item.menu_item_id] = item;
				});
			});
			return obj;
		}, {});

		item.lists = data.composite.lists ? data.composite.lists : [];
		item.lists.forEach(list => {
			list.products = data.composite.lists_products.filter(product => product.menu_item_list_id === list.menu_item_list_id);
			list.menu_item_list_id = Number(list.menu_item_list_id);
			list.select_min = Number(list.select_min);
			list.select_max = Number(list.select_max);
			list.select_multi = Number(list.select_multi);
			list.order_number = Number(list.order_number);
			list.nameTranslation = list.name;
			list.products.forEach(product => {
				product.menu_item_list_product_id = Number(product.menu_item_list_product_id);
				product.menu_item_list_id = Number(product.menu_item_list_id);
				product.menu_item_linked_id = product.menu_item_linked_id ? Number(product.menu_item_linked_id) : null;
				product.meta_product = product.meta_product === '1';
				product.linked_price = product.linked_price === '1';
				product.linked_name = product.linked_name === '1';
				product.available = product.available === '1';
				product.availableQuantity = null;
				product.warehouse = false;
				product.sku = null;
				product.order_number = Number(product.order_number);
				if(product.menu_item_linked_id) {
					let linkedProduct = allItemsObj.hasOwnProperty(product.menu_item_linked_id) ? allItemsObj[product.menu_item_linked_id] : null;
					if(linkedProduct) {
						product.available = linkedProduct.available === null ? linkedProduct.availableTime : linkedProduct.available;
						product.availableQuantity = linkedProduct.availableQuantity;
						product.warehouse = linkedProduct.warehouse;
						product.sku = linkedProduct.sku;
						product.product_price = product.linked_price ? linkedProduct.price : product.product_price;
						product.product_name = product.linked_name ? linkedProduct.name : product.product_name;
						product.product_nameTranslation = product.product_name;
					}
				}
				product.product_price = Number(product.product_price);
				product.product_nameTranslation = product.product_name;
			});
			list.products = list.products.sort((a, b) => {
				return a.order_number - b.order_number;
			});
		});
		item.lists = item.lists.sort((a, b) => {
			return a.order_number - b.order_number;
		});
	}
}

function normalizeLanguages() {
	if(typeof languages === 'undefined') {
		console.warn("Languages not found")
		return [];
	}
	languages.forEach(language => {
		language.language_id = Number(language.language_id);
		language.selectable = language.selectable === '1';
		language.sort_number = Number(language.sort_number);
	});
	languages.sort((a, b) => {
		return a.sort_number - b.sort_number;
	});
	return languages;
}

function normalizeCountries() {
	if(typeof countries === 'undefined') {
		console.warn("Countries not found")
		return [];
	}
	countries.forEach(country => {
		country.country_id = Number(country.country_id);
		country.language_id = Number(country.language_id);
		country.sort_number = Number(country.sort_number);
	});
	countries.sort((a, b) => {
		return a.sort_number - b.sort_number;
	});
	return countries;
}

function normalizeSocials() {
	if(typeof socials === 'undefined' || typeof socialsLink === 'undefined') {
		console.warn("Socials not found")
		return [];
	}
	let socialsResult = [];
	for(let i = 0; i < socialsLink.length; i++) {
		let socialLink = socialsLink[i];
		for(let j = 0; j < socials.length; j++) {
			let social = socials[j];
			if(social.social_id === socialLink.social_id) {
				socialsResult.push({
					social_id: social.social_id,
					type: social.type,
					link: social.url+socialLink.value,
				});
				break;
			}
		}
	}
	return socialsResult;
}

function normalizeWeconstudioInfo() {
	if(typeof weconstudioInfo !== 'undefined' && weconstudioInfo) {
		let weconstudioInfoObj = {};
		weconstudioInfo.forEach((value) => {
			weconstudioInfoObj[value.type] = value.value;
		});
		return weconstudioInfoObj;
	}
	return null;
}

function getImageSize() {
	if(typeof business.options.banner_size === 'undefined') {
		return 250;
	}
	if(business.options.banner_size === "small") {
		return 150;
	} else if(business.options.banner_size === "medium") {
		return 250;
	} else if(business.options.banner_size === "large") {
		return 350;
	} else {
		return 250;
	}
}
