import { defineStore } from 'pinia';
import {useLanguageStore, useNotificationStore, useCurrencyStore} from "@/shared/stores";
import {useMenusStore} from "@/new-menu/stores/menus.store";
import API from "@/shared/helpers/API";
import {useCartStore} from "@/new-menu/stores/cart.store";
import {useEvsStore} from "@/new-menu/stores/evs.store";
import {getLocalDateFromServer} from "@/shared/helpers/dates";

export const useActionsStore = defineStore('actions', {
	state: () => ({
		tableOrders: [],
	}),
	getters: {
		primaryPaymentMethod: (state) => {
			const menusStore = useMenusStore();
			return menusStore.paymentMethodsAvailable.length > 0 ? menusStore.paymentMethodsAvailable[0] : null;
		},
		primaryText: (state) => {
			const languageStore = useLanguageStore();
			const menusStore = useMenusStore();
			if(state.primaryPaymentMethod === "stripe") {
				return languageStore.getString("pay_now");
			} else if(state.primaryPaymentMethod === "cash") {
				return menusStore.business.options.hide_prices ? languageStore.getString("order_now") : languageStore.getString("pay_later");
			} else if(state.primaryPaymentMethod === "klarna") {
				return languageStore.getString("pay_in_3_installments");
			} else if(state.primaryPaymentMethod === "satispay") {
				return languageStore.getString("satispay");
			} else if(state.primaryPaymentMethod === "btc") {
				return languageStore.getString("bitcoin");
			} else {
				return languageStore.getString("pay_now");
			}
		},
		primaryLogos: (state) => {
			if(state.primaryPaymentMethod === "stripe") {
				return [
					"https://img.qromo.io/img/icons/mastercard.svg",
					"https://img.qromo.io/img/icons/apple-pay.svg",
					"https://img.qromo.io/img/icons/google-pay.svg",
				];
			} else if(state.primaryPaymentMethod === "cash") {
				return null;
			} else if(state.primaryPaymentMethod === "klarna") {
				return ["https://img.qromo.io/img/logo-klarna.svg"];
			} else if(state.primaryPaymentMethod === "satispay") {
				return ["https://img.qromo.io/img/icons/satispay-logo-min.png"];
			} else if(state.primaryPaymentMethod === "btc") {
				return ["https://img.qromo.io/img/icons/btc-logo-heading.png"];
			} else {
				return [
					"https://img.qromo.io/img/icons/mastercard.svg",
					"https://img.qromo.io/img/icons/apple-pay.svg",
					"https://img.qromo.io/img/icons/google-pay.svg",
				];
			}
		},
		otherPaymentMethods: (state) =>  {
			const menusStore = useMenusStore();
			return menusStore.paymentMethodsAvailable.filter(pm => pm !== state.primaryPaymentMethod && pm !== "cash" && pm !== "btc");
		},
		logosOtherPayBill: (state) => {
			let result = state.logosOther;
			if(state.otherPaymentMethods.includes("voucherly")) {
				result.push("https://img.qromo.io/img/icons/voucherly.png");
			}
			return result;
		},
		logosOther: (state) => {
			let result = [];
			if(state.otherPaymentMethods.includes("klarna")) {
				result.push("https://img.qromo.io/img/logo-klarna.svg");
			}
			if(state.otherPaymentMethods.includes("satispay")) {
				result.push("https://img.qromo.io/img/icons/satispay-logo-min.png");
			}
			if(state.otherPaymentMethods.includes("btc")) {
				result.push("https://img.qromo.io/img/icons/btc-logo-heading.png");
			}
			return result;
		},
		payBillAmount: (state) => {
			return state.tableOrders.reduce((acc, order) => {
				return acc + order.grand_total - order.paymentsPartial;
			}, 0);
		},
	},
	actions: {
		payBill(method, amount, typeIndex, itemsRedeemed, callback) {

			const menusStore = useMenusStore();
			const cartStore = useCartStore();

			cartStore.execCaptcha((success, captchaToken) => {

				if(success) {

					useEvsStore().enqueueEvent('checkout-pay-bill', method, JSON.stringify({
						amount: amount,
					}));

					let tkn = typeof tknTmp !== 'undefined' ? tknTmp : null;
					let orderIds = this.tableOrders.map(order => order.order_id);
					API.init().payBill(method, menusStore.business.business_id, amount, captchaToken, tkn, menusStore.qrVersion, orderIds, typeIndex, itemsRedeemed, (response) => {

						if(response && response.result === "OK") {
							callback(response);
						} else {
							if(response.details) {
								cartStore.handleCheckoutError(response, true);
							}
							callback(null);
						}

					});

				} else {
					let notificationStore = useNotificationStore();
					notificationStore.showNotification("invalid_captcha");
					callback(null);
				}

			});
		},
		normalizeOrders(orders, items, itemsProducts, multiPayments) {
			let result = [];

			let ordersObj = {};
			orders.forEach(order => {

				let languageStore = useLanguageStore();
				order.order_id = Number(order.order_id);
				order.total = Number(order.total);
				order.subtotal_delta = Number(order.subtotal_delta);
				order.delivery_costs_value = Number(order.delivery_costs_value);
				order.takeaway_costs_value = Number(order.takeaway_costs_value);
				order.cover_charge_value = Number(order.cover_charge_value);
				order.cover_charge_num = Number(order.cover_charge_num);
				order.cover_charge_paid = Number(order.cover_charge_paid);
				order.grand_total = order.total + order.subtotal_delta + order.delivery_costs_value + order.takeaway_costs_value + (order.cover_charge_value * order.cover_charge_num);
				order.grand_total_formatted = useCurrencyStore().readableCurrencyValue(order.grand_total, true, true, true);
				order.items = {};
				order.payments = [];
				order.paymentsPartial = 0;

				ordersObj[order.order_id] = order;

			});

			items.forEach(item => {
				item.order_item_id = Number(item.order_item_id);
				item.item_id = Number(item.item_id);
				item.order_id = Number(item.order_id);
				item.quantity = Number(item.quantity);
				item.price = Number(item.price);
				item.products = [];

				if (ordersObj.hasOwnProperty(item.order_id)) {
					ordersObj[item.order_id].items[item.order_item_id] = item;
				}

			});

			itemsProducts.forEach(itemProduct=> {
				itemProduct.order_id = Number(itemProduct.order_id);
				itemProduct.order_item_id = Number(itemProduct.order_item_id);
				itemProduct.quantity = Number(itemProduct.quantity);
				itemProduct.price = Number(itemProduct.price);

				if(ordersObj.hasOwnProperty(itemProduct.order_id) && ordersObj[itemProduct.order_id].items.hasOwnProperty(itemProduct.order_item_id)) {
					ordersObj[itemProduct.order_id].items[itemProduct.order_item_id].products.push(itemProduct);
				}
			});

			multiPayments.forEach(multiPayment=> {
				multiPayment.order_id = Number(multiPayment.order_id);
				multiPayment.amount = Number(multiPayment.amount);

				if(ordersObj.hasOwnProperty(multiPayment.order_id)) {
					ordersObj[multiPayment.order_id].payments.push(multiPayment);
					ordersObj[multiPayment.order_id].paymentsPartial += multiPayment.amount;
				}

			});

			let ordersArray = Object.values(ordersObj);
			ordersArray.forEach(order => {
				order.items = Object.values(order.items);
			});


			this.tableOrders = ordersArray;
		}
	},
});